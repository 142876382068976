import React, { useState, useEffect, useRef } from 'react';
import { ArrowRight, Circle, Cpu, DollarSign, LineChart, Moon, Sun, Star, ExternalLink, Github, Linkedin, Twitter, Mail } from 'lucide-react';

const App = () => {
  const [scrollY, setScrollY] = useState(0);
  const [activeSection, setActiveSection] = useState('hero');
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [theme, setTheme] = useState('dark');
  const [mouseEntered, setMouseEntered] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [titleVisible, setTitleVisible] = useState(false);
  const heroRef = useRef(null);
  const keywordsContainerRef = useRef(null);

  // Set loaded state after component mounts for entrance animations
  useEffect(() => {
    setTimeout(() => setIsLoaded(true), 300);
  }, []);

  // Then update the useEffect hook to trigger the title visibility
  useEffect(() => {
    if (isLoaded) {
      // Set title visible after the page has loaded
      setTimeout(() => {
        setTitleVisible(true);
      }, 300);
    }
  }, [isLoaded]);

  // Handle scroll effects
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
      
      // Determine active section based on scroll position
      const sections = ['hero', 'impact', 'crypto', 'ai', 'bazi'];
      const currentSection = sections.find((section, index) => {
        const element = document.getElementById(section);
        if (!element) return false;
        const top = element.offsetTop;
        const height = element.offsetHeight;
        return scrollY >= top - 300 && scrollY < top + height - 300;
      });
      
      if (currentSection) {
        setActiveSection(currentSection);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollY]);

  // Track cursor for custom effects
  useEffect(() => {
    const handleMouseMove = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  // Toggle theme
  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  // 3D tilt effect for cards
  const handleMouseMove3D = (e, intensity = 20) => {
    if (!e.currentTarget) return;
    
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    
    const rotateX = (y - centerY) / centerY * -intensity;
    const rotateY = (x - centerX) / centerX * intensity;
    
    card.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
  };
  
  const resetCard = (e) => {
    if (!e.currentTarget) return;
    e.currentTarget.style.transform = 'perspective(1000px) rotateX(0deg) rotateY(0deg)';
  };

  // Add this helper function somewhere in your component (before the return statement)
  const getSDGColor = (num) => {
    const colors = {
      1: '#E5243B', // No Poverty
      3: '#4C9F38', // Good Health
      4: '#C5192D', // Quality Education 
      6: '#26BDE2', // Clean Water
      7: '#FCC30B', // Affordable Energy
      8: '#A21942', // Decent Work
      9: '#FD6925', // Industry & Innovation
      11: '#FD9D24', // Sustainable Cities
      12: '#BF8B2E', // Responsible Consumption
      13: '#3F7E44'  // Climate Action
    };
    return colors[num] || '#333333';
  };

  // Add Element Mapping Helper Function (near getSDGColor)
  const getElementInfo = (char) => {
    const elements = {
      Wood: { color: '#4ade80', stems: ['甲', '乙'], branches: ['寅', '卯'] },
      Fire: { color: '#f87171', stems: ['丙', '丁'], branches: ['巳', '午'] },
      Earth: { color: '#8B4513', stems: ['戊', '己'], branches: ['辰', '戌', '丑', '未'] },
      Metal: { color: '#FFD700', stems: ['庚', '辛'], branches: ['申', '酉'] }, // Changed from #d1d5db to #FFD700 (gold)
      Water: { color: '#60a5fa', stems: ['壬', '癸'], branches: ['亥', '子'] },
    };
    for (const [name, data] of Object.entries(elements)) {
      if (data.stems.includes(char) || data.branches.includes(char)) {
        return { name, color: data.color };
      }
    }
    return { name: 'Unknown', color: '#9ca3af' }; // Default
  };

  const handleMouseMoveKeywords = (e) => {
    if (!keywordsContainerRef.current) return;
    
    const keywords = keywordsContainerRef.current.querySelectorAll('.keyword-item');
    const container = keywordsContainerRef.current.getBoundingClientRect();
    
    // Calculate mouse position relative to container center
    const centerX = container.width / 2;
    const centerY = container.height / 2;
    const mouseX = e.clientX - container.left - centerX;
    const mouseY = e.clientY - container.top - centerY;
    
    // Move each keyword based on mouse position
    keywords.forEach((keyword, index) => {
      const factor = (index % 5 + 1) * 0.01; // Different movement factors for variety
      const x = mouseX * factor;
      const y = mouseY * factor;
      keyword.style.transform = `translate(${x}px, ${y}px) scale(${0.8 + Math.random() * 0.7})`;
    });
  };

  // Add this useEffect hook after other useEffect hooks in the component
  useEffect(() => {
    // Function to fetch crypto prices from CoinGecko API
    const fetchCryptoPrices = async () => {
      try {
        // Use the markets endpoint instead of simple/price for more reliable percentage changes
        const response = await fetch(
          'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin,ethereum,binancecoin,solana,cardano,avalanche-2,polkadot,tron,matic-network,dogecoin,shiba-inu&order=market_cap_desc&per_page=20&page=1&sparkline=false&price_change_percentage=24h',
          {
            headers: {
              'x-cg-api-key': 'CG-WyW4R5Es4sDaGWbYqftmUH24'
            }
          }
        );
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const data = await response.json();
        updatePriceTicker(data);
      } catch (error) {
        console.error('Error fetching crypto prices:', error);
        // Use fallback data if API fails
        updatePriceTicker(null);
      }
    };

    // Function to update the price ticker with live data
    const updatePriceTicker = (data) => {
      const ticker = document.getElementById('crypto-price-ticker');
      if (!ticker) return;
      
      // Clear current content
      ticker.innerHTML = '';
      
      // Sample fallback data in case API fails - formatted like the markets endpoint
      const fallbackData = [
        { id: 'bitcoin', symbol: 'btc', current_price: 45230, price_change_percentage_24h: 2.4 },
        { id: 'ethereum', symbol: 'eth', current_price: 2450, price_change_percentage_24h: -1.2 },
        { id: 'binancecoin', symbol: 'bnb', current_price: 320, price_change_percentage_24h: 1.8 },
        { id: 'solana', symbol: 'sol', current_price: 98, price_change_percentage_24h: -2.3 },
        { id: 'cardano', symbol: 'ada', current_price: 0.35, price_change_percentage_24h: -0.8 },
        { id: 'avalanche-2', symbol: 'avax', current_price: 22, price_change_percentage_24h: 3.5 },
        { id: 'polkadot', symbol: 'dot', current_price: 5.2, price_change_percentage_24h: 1.5 },
        { id: 'tron', symbol: 'trx', current_price: 0.11, price_change_percentage_24h: 0.4 },
        { id: 'matic-network', symbol: 'matic', current_price: 0.6, price_change_percentage_24h: 2.1 },
        { id: 'dogecoin', symbol: 'doge', current_price: 0.08, price_change_percentage_24h: 5.2 },
        { id: 'shiba-inu', symbol: 'shib', current_price: 0.000008, price_change_percentage_24h: -3.1 }
      ];
      
      // Use API data or fallback
      const priceData = data || fallbackData;
      
      // Order is already handled by the markets endpoint with order=market_cap_desc,
      // but let's double-check to make sure they appear in market cap order
      const cryptoData = [...priceData].sort((a, b) => {
        // If IDs match predefined order, use that order
        const ids = ['bitcoin', 'ethereum', 'binancecoin', 'solana', 'cardano', 'avalanche-2', 'polkadot', 'tron', 'matic-network', 'dogecoin', 'shiba-inu'];
        const aIndex = ids.indexOf(a.id);
        const bIndex = ids.indexOf(b.id);
        if (aIndex >= 0 && bIndex >= 0) {
          return aIndex - bIndex;
        }
        // Otherwise, sort by price (as a fallback)
        return b.current_price - a.current_price;
      });
      
      // Create display elements
      const createPriceElements = (ticker) => {
        cryptoData.forEach((coin, i) => {
          // Format the price
          const price = coin.current_price;
          const priceFormatted = price < 0.01 ? price.toFixed(6) : price.toFixed(2);
          
          // Get the percentage change - reliable from the markets endpoint
          let change = 0;
          if (coin.price_change_percentage_24h !== undefined && coin.price_change_percentage_24h !== null) {
            change = parseFloat(coin.price_change_percentage_24h).toFixed(1);
          }
          
          const isPositive = parseFloat(change) >= 0;
          
          // Create the element
          const item = document.createElement('div');
          item.className = 'flex items-center px-4';
          item.innerHTML = `
            <span class="text-xs font-medium text-amber-500">${coin.symbol.toUpperCase()} $${priceFormatted}</span>
            <span class="ml-1 text-xs ${isPositive ? 'text-green-500' : 'text-red-500'}">
              ${isPositive ? '+' : ''}${change}%
            </span>
          `;
          
          ticker.appendChild(item);
        });
        
        // Clone elements for continuous scroll
        cryptoData.forEach((coin, i) => {
          // Format the price
          const price = coin.current_price;
          const priceFormatted = price < 0.01 ? price.toFixed(6) : price.toFixed(2);
          
          // Get the percentage change
          let change = 0;
          if (coin.price_change_percentage_24h !== undefined && coin.price_change_percentage_24h !== null) {
            change = parseFloat(coin.price_change_percentage_24h).toFixed(1);
          }
          
          const isPositive = parseFloat(change) >= 0;
          
          // Create the element
          const item = document.createElement('div');
          item.className = 'flex items-center px-4';
          item.innerHTML = `
            <span class="text-xs font-medium text-amber-500">${coin.symbol.toUpperCase()} $${priceFormatted}</span>
            <span class="ml-1 text-xs ${isPositive ? 'text-green-500' : 'text-red-500'}">
              ${isPositive ? '+' : ''}${change}%
            </span>
          `;
          
          ticker.appendChild(item);
        });
      };
      
      createPriceElements(ticker);
    };
    
    // Initial fetch
    fetchCryptoPrices();
    
    // Set interval to update prices every 60 seconds
    const intervalId = setInterval(fetchCryptoPrices, 60000);
    
    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array means this runs once when component mounts

  // Add touch-friendly interactions for mobile
  const handleTouchStart = (e) => {
    // Store initial touch position
    if (!e.currentTarget) return;
    const touch = e.touches[0];
    e.currentTarget._touchX = touch.clientX;
    e.currentTarget._touchY = touch.clientY;
  };

  const handleTouchMove = (e) => {
    // Create 3D effect for touch devices
    if (!e.currentTarget || !e.currentTarget._touchX) return;
    const touch = e.touches[0];
    const deltaX = touch.clientX - e.currentTarget._touchX;
    const deltaY = touch.clientY - e.currentTarget._touchY;
    
    const rotateY = deltaX * 0.2;
    const rotateX = -deltaY * 0.2;
    
    e.currentTarget.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
  };

  return (
    <div className={`min-h-screen w-full ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'} transition-colors duration-500 overflow-hidden`}>
      {/* Dynamic cursor */}
      <div 
        className="fixed w-8 h-8 rounded-full pointer-events-none z-50 mix-blend-difference transition-transform duration-200 ease-out"
        style={{
          background: 'radial-gradient(circle, rgba(99,102,241,0.8) 0%, rgba(99,102,241,0) 70%)',
          transform: `translate(${cursorPosition.x - 16}px, ${cursorPosition.y - 16}px) scale(${mouseEntered ? 3 : 1})`,
          filter: 'blur(4px)',
        }}
      />
      <div 
        className="fixed w-4 h-4 bg-white rounded-full pointer-events-none z-50 transition-transform duration-100 ease-out"
        style={{
          transform: `translate(${cursorPosition.x - 2}px, ${cursorPosition.y - 2}px)`,
          opacity: 0.8,
        }}
      />

      {/* Futuristic grid background */}
      <div className="fixed inset-0 pointer-events-none z-0">
        <div className={`w-full h-full ${theme === 'dark' ? 'opacity-20' : 'opacity-5'}`} 
          style={{
            backgroundImage: `
              linear-gradient(to right, rgba(99,102,241,0.1) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(99,102,241,0.1) 1px, transparent 1px)
            `,
            backgroundSize: '40px 40px',
            transform: `translateY(${scrollY * 0.1}px)`,
          }}
        />
      </div>

      {/* Floating particles effect */}
      <div className="fixed inset-0 pointer-events-none overflow-hidden z-0">
        {[...Array(30)].map((_, i) => (
          <div
            key={i}
            className="absolute rounded-full opacity-20"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              width: `${Math.random() * 15 + 2}px`,
              height: `${Math.random() * 15 + 2}px`,
              background: i % 5 === 0 ? '#61dafb' : i % 5 === 1 ? '#f7931a' : i % 5 === 2 ? '#4caf50' : i % 5 === 3 ? '#e91e63' : '#9c27b0',
              transform: `translate(${Math.sin((scrollY + i * 100) / 1000) * 150}px, ${Math.cos((scrollY + i * 100) / 1000) * 150}px)`,
              filter: 'blur(1px)',
              transition: 'transform 0.5s ease-out',
              boxShadow: `0 0 20px 2px ${i % 5 === 0 ? 'rgba(97, 218, 251, 0.3)' : i % 5 === 1 ? 'rgba(247, 147, 26, 0.3)' : i % 5 === 2 ? 'rgba(76, 175, 80, 0.3)' : i % 5 === 3 ? 'rgba(233, 30, 99, 0.3)' : 'rgba(156, 39, 176, 0.3)'}`,
            }}
          />
        ))}
      </div>
      
      {/* Radial gradient overlays for depth */}
      <div className="fixed inset-0 pointer-events-none z-0 opacity-70"
           style={{
             background: theme === 'dark' 
               ? 'radial-gradient(circle at 50% 50%, rgba(20, 20, 30, 0) 0%, rgba(20, 20, 30, 0.8) 100%)' 
               : 'radial-gradient(circle at 50% 50%, rgba(235, 235, 245, 0) 0%, rgba(235, 235, 245, 0.8) 100%)'
           }}
      />

      {/* Theme toggle button */}
      <button 
        onClick={toggleTheme}
        onMouseEnter={() => setMouseEntered(true)}
        onMouseLeave={() => setMouseEntered(false)}
        className="fixed top-6 right-6 z-50 p-3 rounded-full bg-opacity-20 backdrop-blur-md transition-all duration-500 hover:rotate-90 hover:scale-110"
        style={{
          background: theme === 'dark' ? 'rgba(30, 30, 40, 0.4)' : 'rgba(240, 240, 250, 0.4)',
          boxShadow: '0 0 20px rgba(99, 102, 241, 0.3)',
        }}
      >
        {theme === 'dark' ? <Sun size={20} /> : <Moon size={20} />}
      </button>

      {/* Navigation */}
      <nav 
        className={`fixed top-0 w-full z-40 transition-all duration-500 ${
          scrollY > 50 ? 'py-3 backdrop-blur-md' : 'py-6'
        } ${theme === 'dark' ? 'bg-gray-900/80' : 'bg-white/80'}`}
        style={{
          transform: `translateY(${isLoaded ? '0' : '-100px'})`,
          boxShadow: scrollY > 50 ? '0 5px 30px rgba(0, 0, 0, 0.1)' : 'none',
        }}
      >
        <div className="container mx-auto px-6 flex items-center justify-between">
          <div className="text-xl font-bold relative overflow-visible">
            <a 
              href="http://linkedin.com/in/josephl1/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="flex items-center gap-2 hover:opacity-80 transition-opacity"
            >
              <Linkedin size={24} className="text-indigo-500" />
              <span className="bg-gradient-to-r from-purple-400 via-indigo-500 to-violet-600 text-transparent bg-clip-text whitespace-nowrap">
                LinkedIn
              </span>
            </a>
          </div>
          <div className="hidden md:flex space-x-10">
            {['Impact', 'Crypto', 'AI', 'Bazi'].map((item) => (
              <a
                key={item}
                href={`#${item.toLowerCase()}`}
                onMouseEnter={() => setMouseEntered(true)}
                onMouseLeave={() => setMouseEntered(false)}
                className={`transition-all duration-300 relative hover:tracking-wider ${
                  activeSection === item.toLowerCase() 
                    ? 'font-medium text-indigo-500' 
                    : 'opacity-70 hover:opacity-100'
                }`}
              >
                {item}
                <span 
                  className={`absolute -bottom-1 left-0 h-0.5 bg-indigo-500 transition-all duration-500 rounded-full ${
                    activeSection === item.toLowerCase() ? 'w-full' : 'w-0'
                  }`} 
                />
              </a>
            ))}
          </div>
          <button className="md:hidden text-2xl">☰</button>
        </div>
      </nav>

      {/* Hero Section */}
      <section id="hero" ref={heroRef} className="min-h-screen flex items-center justify-center relative overflow-hidden">
        <div className="absolute inset-0 z-0">
          {/* Replace static image with animated keywords background */}
          <div 
            ref={keywordsContainerRef}
            className="absolute inset-0 w-full h-full overflow-hidden"
            onMouseMove={handleMouseMoveKeywords}
          >
            <div className="keywords-container">
              {[
                'Innovation', 'Sustainability', 'Impact', 'Technology', 'Future',
                'Growth', 'Transformation', 'Development', 'Progress', 'AI',
                'Clean Energy', 'Climate Action', 'Empowerment', 'Blockchain',
                'Digital', 'Equality', 'Global Goals', 'Community', 'Solutions',
                'Renewable', 'Smart Cities', 'ESG', 'Circular Economy', 'Net Zero'
              ].map((word, index) => (
                <div 
                  key={index}
                  className="keyword-item"
                  style={{
                    left: `${5 + (index % 8) * 12}%`, // Better distribution horizontally
                    top: `${10 + Math.floor(index / 8) * 20}%`, // Better distribution vertically
                    animationDelay: `${index * 0.3}s`,
                    opacity: 0.1 + (Math.random() * 0.3),
                    color: index % 5 === 0 ? '#818cf8' : 
                           index % 5 === 1 ? '#a78bfa' : 
                           index % 5 === 2 ? '#f472b6' : 
                           index % 5 === 3 ? '#60a5fa' : '#4ade80', // Added more colors
                  }}
                >
                  {word}
                </div>
              ))}
            </div>
          </div>
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-gray-900" />
        </div>

        <div className="container mx-auto px-4 sm:px-6 relative z-10">
          <div className="flex flex-col items-center justify-center text-center">
            <div className="w-full max-w-3xl mx-auto">
              <h1 className="text-5xl sm:text-6xl md:text-7xl lg:text-9xl font-bold mb-6 tracking-tight transition-all duration-1000 ease-out"
                   style={{
                     transform: `translateY(${isLoaded ? '0' : '50px'})`,
                     opacity: isLoaded ? 1 : 0,
                   }}>
                {/* Title content */}
                <div className="overflow-hidden relative">
                  <span className="inline-block transition-transform duration-500" 
                        style={{ transform: `translateY(${titleVisible ? '0' : '100%'})` }}>
                    Hello, I'm
                  </span>
                </div>
                <div className="overflow-hidden relative mt-2 pb-4" style={{ lineHeight: "1.2" }}>
                  <span className={`inline-block transition-transform duration-500 delay-300 bg-gradient-to-r from-yellow-400 via-amber-500 to-orange-500 text-transparent bg-clip-text`}
                        style={{ 
                          transform: `translateY(${titleVisible ? '0' : '100%'})`,
                          paddingBottom: "8px" // Add padding to the text itself
                        }}>
                    Joseph Leung
                  </span>
                </div>
              </h1>
              
              <div className="mb-12 text-lg md:text-xl opacity-80"
                   style={{
                     transform: `translateY(${isLoaded ? '0' : '30px'})`,
                     opacity: isLoaded ? 1 : 0,
                     transition: 'transform 1s ease-out 0.3s, opacity 1s ease-out 0.3s',
                   }}>
                {/* Subtitle content */}
              </div>
            </div>
          </div>
        </div>
        
        {/* Animated background elements */}
        <div className="absolute inset-0 overflow-hidden z-0">
          <div 
            className="absolute top-1/4 left-1/4 w-96 h-96 rounded-full opacity-20 filter blur-3xl" 
            style={{ 
              background: 'linear-gradient(to right, #6366f1, #8b5cf6)',
              transform: `translate(${-scrollY * 0.15}px, ${scrollY * 0.05}px)`,
              animation: 'pulse 8s infinite alternate',
            }} 
          />
          <div 
            className="absolute bottom-1/4 right-1/4 w-96 h-96 rounded-full opacity-20 filter blur-3xl" 
            style={{ 
              background: 'linear-gradient(to right, #ec4899, #8b5cf6)',
              transform: `translate(${scrollY * 0.12}px, ${-scrollY * 0.05}px)`,
              animation: 'pulse 12s infinite alternate-reverse',
            }} 
          />
        </div>
      </section>

      {/* Impact Investing Section */}
      <section 
        id="impact" 
        className={`min-h-screen flex items-center relative py-24 ${theme === 'dark' ? 'bg-gray-800/50' : 'bg-gray-200/50'} backdrop-blur-md`}
      >
        <div className="absolute inset-0 overflow-hidden">
          <img 
            src="/api/placeholder/1920/1080" 
            alt="Sustainable finance" 
            className="w-full h-full object-cover opacity-10"
          />
          <div className={`absolute inset-0 bg-gradient-to-b ${theme === 'dark' ? 'from-gray-900 via-transparent to-gray-900' : 'from-gray-100 via-transparent to-gray-100'}`} />
        </div>

        <div className="container mx-auto px-6 relative z-10">
          <div className="flex flex-col md:flex-row items-center gap-12">
            <div className="flex-1 relative">
              <div className={`absolute -top-10 -left-10 text-9xl opacity-5 font-bold ${theme === 'dark' ? 'text-green-300' : 'text-green-700'}`}>01</div>
              <div 
                className="overflow-hidden mb-4"
                style={{
                  transform: `translateY(${Math.max(0, 100 - Math.max(0, scrollY - 300) * 0.5)}px)`,
                  opacity: Math.min(1, Math.max(0, scrollY - 300) / 200),
                  transition: 'transform 0.8s ease-out, opacity 0.8s ease-out',
                }}
              >
                <h2 className="text-4xl md:text-6xl font-bold mb-2 relative bg-gradient-to-r from-green-400 to-emerald-600 text-transparent bg-clip-text">
                  Impact Investing
                </h2>
                <div className="h-1 w-24 bg-gradient-to-r from-green-500 to-emerald-700 rounded-full" />
              </div>
              
              <div 
                className="text-lg mb-6 opacity-80 max-w-xl"
                style={{
                  transform: `translateY(${Math.max(0, 120 - Math.max(0, scrollY - 300) * 0.5)}px)`,
                  opacity: Math.min(1, Math.max(0, scrollY - 300) / 250),
                  transition: 'transform 1s ease-out, opacity 1s ease-out',
                }}
              >
                <p>Catalyzing positive change through strategic investments that deliver both financial returns and measurable social or environmental impact. My portfolio focuses on climate technologies, healthcare innovation, financial inclusion initiatives, educational advancement, and equitable access to essential resources.</p>
              </div>
              
              <div 
                className="flex flex-wrap gap-3 mb-8"
                style={{
                  transform: `translateY(${Math.max(0, 140 - Math.max(0, scrollY - 300) * 0.5)}px)`,
                  opacity: Math.min(1, Math.max(0, scrollY - 300) / 300),
                  transition: 'transform 1.2s ease-out, opacity 1.2s ease-out',
                }}
              >
                {['Sustainability', 'Impact Investing', 'ESG', 'Climate Tech', 'Social Innovation'].map((tag, i) => (
                  <span 
                    key={tag} 
                    className={`px-4 py-1 rounded-full text-sm relative overflow-hidden group`}
                    style={{
                      transition: 'all 0.3s ease-out',
                      transitionDelay: `${i * 100}ms`,
                    }}
                  >
                    <span className={`absolute inset-0 ${theme === 'dark' ? 'bg-green-900/60' : 'bg-green-100'} group-hover:scale-110 transition-transform duration-300`}></span>
                    <span className="relative z-10">{tag}</span>
                  </span>
                ))}
              </div>
              
              <div 
                className="flex items-center gap-6"
                style={{
                  transform: `translateY(${Math.max(0, 160 - Math.max(0, scrollY - 300) * 0.5)}px)`,
                  opacity: Math.min(1, Math.max(0, scrollY - 300) / 350),
                  transition: 'transform 1.4s ease-out, opacity 1.4s ease-out',
                }}
              >
                <div className="flex items-center gap-2">
                  <LineChart size={20} className="text-green-500" />
                  <span>Portfolio Impact</span>
                </div>
                <div className="flex items-center gap-2">
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 24 24" 
                    width="20" 
                    height="20" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    className="text-emerald-500"
                  >
                    <path d="M2 22c1.25-1.25 2.5-2.5 3.75-2.5 1.25 0 1.25 1.25 2.5 1.25 1.25 0 1.25-1.25 2.5-1.25 1.25 0 1.25 1.25 2.5 1.25 1.25 0 1.25-1.25 2.5-1.25 1.25 0 2.5 1.25 3.75 2.5"></path>
                    <path d="M20 17c-5.89-5.89-13.75-2.12-18.77 2.9"></path>
                    <path d="M20 7v10"></path>
                    <path d="M16 3v4h4"></path>
                    <path d="M16 3c-5 0-5 8-10 8"></path>
                  </svg>
                  <span>Sustainable Returns</span>
                </div>
              </div>
            </div>
            
            <div 
              className="flex-1 relative"
              style={{
                transform: `translateX(${Math.max(0, 100 - Math.max(0, scrollY - 300) * 0.5)}px)`,
                opacity: Math.min(1, Math.max(0, scrollY - 300) / 300),
                transition: 'transform 1s ease-out, opacity 1s ease-out',
              }}
            >
              <div 
                className={`relative w-full aspect-square rounded-2xl overflow-hidden shadow-2xl`}
                onMouseMove={(e) => handleMouseMove3D(e, 15)}
                onMouseLeave={resetCard}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={resetCard}
                style={{
                  background: theme === 'dark' ? 'rgba(20, 20, 20, 0.7)' : 'rgba(255, 255, 255, 0.7)',
                  backdropFilter: 'blur(10px)',
                  boxShadow: theme === 'dark' 
                    ? '0 25px 50px -12px rgba(0, 0, 0, 0.5), 0 0 30px rgba(16, 185, 129, 0.2)' 
                    : '0 25px 50px -12px rgba(0, 0, 0, 0.1), 0 0 30px rgba(16, 185, 129, 0.1)',
                  transformStyle: 'preserve-3d',
                  transition: 'transform 0.2s ease-out',
                }}
              >
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="w-4/5 h-4/5 p-6 transform" style={{ transform: 'translateZ(20px)' }}>
                    {/* Impact visualization */}
                    <div className="w-full h-full flex flex-col">
                      <div className="text-xl font-semibold mb-6 text-center">Selected UN Sustainable Development Goals (UN SDGs)</div>
                      
                      <div className="flex-1 flex flex-wrap justify-center items-center gap-4" style={{ transform: 'translateZ(40px)' }}>
                        {[
                          {number: 1, name: 'No Poverty', color: '#E5243B', description: 'End poverty in all its forms everywhere', targets: 7, url: 'https://sdgs.un.org/goals/goal1'},
                          {number: 3, name: 'Good Health', color: '#4C9F38', description: 'Ensure healthy lives and promote well-being for all at all ages', targets: 13, url: 'https://sdgs.un.org/goals/goal3'},
                          {number: 4, name: 'Quality Education', color: '#C5192D', description: 'Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all', targets: 10, url: 'https://sdgs.un.org/goals/goal4'},
                          {number: 6, name: 'Clean Water', color: '#26BDE2', description: 'Ensure availability and sustainable management of water and sanitation for all', targets: 8, url: 'https://sdgs.un.org/goals/goal6'},
                          {number: 7, name: 'Affordable Energy', color: '#FCC30B', description: 'Ensure access to affordable, reliable, sustainable and modern energy for all', targets: 5, url: 'https://sdgs.un.org/goals/goal7'},
                          {number: 8, name: 'Decent Work', color: '#A21942', description: 'Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all', targets: 12, url: 'https://sdgs.un.org/goals/goal8'},
                          {number: 9, name: 'Industry & Innovation', color: '#FD6925', description: 'Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation', targets: 8, url: 'https://sdgs.un.org/goals/goal9'},
                          {number: 11, name: 'Sustainable Cities', color: '#FD9D24', description: 'Make cities and human settlements inclusive, safe, resilient and sustainable', targets: 10, url: 'https://sdgs.un.org/goals/goal11'},
                          {number: 12, name: 'Responsible Consumption', color: '#BF8B2E', description: 'Ensure sustainable consumption and production patterns', targets: 11, url: 'https://sdgs.un.org/goals/goal12'},
                          {number: 13, name: 'Climate Action', color: '#3F7E44', description: 'Take urgent action to combat climate change and its impacts', targets: 5, url: 'https://sdgs.un.org/goals/goal13'}
                        ].map((goal, i) => (
                          <a 
                            key={i} 
                            href={goal.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="relative group cursor-pointer"
                            style={{
                              animation: `fadeIn 0.8s ${i * 0.1}s forwards`,
                              opacity: 0,
                            }}
                            onMouseEnter={() => setMouseEntered(true)}
                            onMouseLeave={() => setMouseEntered(false)}
                          >
                            <div 
                              className="w-24 h-24 md:w-28 md:h-28 rounded-lg flex items-center justify-center relative overflow-hidden"
                              style={{ 
                                backgroundColor: goal.color,
                                boxShadow: `0 5px 15px ${goal.color}50`,
                                transform: `scale(1) translateY(${Math.sin(Date.now()/1000 + i) * 5}px)`,
                                transition: 'transform 0.3s ease-out, box-shadow 0.3s ease-out',
                                animation: `float ${3 + i % 2}s infinite ease-in-out alternate`,
                              }}
                            >
                              {/* Keep original image */}
                              <img 
                                src={`/Images/sdg${goal.number.toString().padStart(2, '0')}.png`}
                                alt={goal.name} 
                                className="w-full h-full object-contain p-2"
                              />
                              
                              {/* Hover overlay with additional information */}
                              <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-90 transition-opacity duration-300 flex flex-col justify-center items-center p-2 text-xs text-white">
                                <div className="font-bold mb-1">Goal {goal.number}</div>
                                <div className="text-center line-clamp-3 mb-1">{goal.description}</div>
                                <div className="flex items-center gap-2 mt-1">
                                  <div className="px-2 py-0.5 rounded-full bg-white/20">{goal.targets} Targets</div>
                                  <div className="px-2 py-0.5 rounded-full bg-white/20">
                                    <ExternalLink size={10} className="inline mr-1" />
                                    View Details
                                  </div>
                                </div>
                              </div>
                            </div>
                            
                            {/* Animated border on hover */}
                            <div className="absolute inset-0 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" 
                              style={{
                                background: `linear-gradient(90deg, transparent, ${goal.color}, transparent)`,
                                backgroundSize: '200% 100%',
                                animation: 'shimmer 2s infinite',
                                filter: 'blur(4px)',
                                transform: 'scale(1.05)',
                                zIndex: -1
                              }}>
                            </div>
                          </a>
                        ))}
                      </div>
                      
                      <div className="mt-auto text-center">
                        <div className="text-sm opacity-70 mb-2">Impact Investment Focus Areas</div>
                        <div className="text-3xl font-bold text-green-500 flex items-center justify-center gap-2">
                          Sustainable Future
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Crypto Section */}
      <section id="crypto" className="min-h-screen flex items-center relative py-24">
        <div className="absolute inset-0 overflow-hidden">
          <img 
            src="/api/placeholder/1920/1080" 
            alt="Blockchain technology" 
            className="w-full h-full object-cover opacity-10"
          />
          <div className={`absolute inset-0 bg-gradient-to-b ${theme === 'dark' ? 'from-gray-900 via-transparent to-gray-900' : 'from-gray-100 via-transparent to-gray-100'}`} />
        </div>

        <div className="container mx-auto px-6 relative z-10">
          <div className="flex flex-col md:flex-row-reverse items-center gap-12">
            <div className="flex-1 relative">
              <div className={`absolute -top-10 -left-10 text-9xl opacity-5 font-bold ${theme === 'dark' ? 'text-orange-300' : 'text-orange-700'}`}>02</div>
              <div 
                className="overflow-hidden mb-4"
                style={{
                  transform: `translateY(${Math.max(0, 100 - Math.max(0, scrollY - 900) * 0.5)}px)`,
                  opacity: Math.min(1, Math.max(0, scrollY - 900) / 200),
                  transition: 'transform 0.8s ease-out, opacity 0.8s ease-out',
                }}
              >
                <h2 className="text-4xl md:text-6xl font-bold mb-2 relative bg-gradient-to-r from-orange-400 to-amber-600 text-transparent bg-clip-text">
                  Crypto Enthusiast
                </h2>
                <div className="h-1 w-24 bg-gradient-to-r from-orange-500 to-amber-700 rounded-full" />
              </div>
              
              <div 
                className="text-lg mb-6 opacity-80 max-w-xl"
                style={{
                  transform: `translateY(${Math.max(0, 120 - Math.max(0, scrollY - 900) * 0.5)}px)`,
                  opacity: Math.min(1, Math.max(0, scrollY - 900) / 250),
                  transition: 'transform 1s ease-out, opacity 1s ease-out',
                }}
              >
                <p>Navigating the frontier of decentralized finance(DeFi), blockchain technology, and digital assets to reshape the future of value exchange. From pioneering DeFi protocols to emerging memecoins, I actively participate across the evolving crypto ecosystem.</p>
              </div>
              
              <div 
                className="flex flex-wrap gap-3 mb-8"
                style={{
                  transform: `translateY(${Math.max(0, 140 - Math.max(0, scrollY - 900) * 0.5)}px)`,
                  opacity: Math.min(1, Math.max(0, scrollY - 900) / 300),
                  transition: 'transform 1.2s ease-out, opacity 1.2s ease-out',
                }}
              >
                {['Blockchain', 'DeFi', 'Web3', 'Memecoins', 'Digital Assets'].map((tag, i) => (
                  <span 
                    key={tag} 
                    className={`px-4 py-1 rounded-full text-sm relative overflow-hidden group`}
                    style={{
                      transition: 'all 0.3s ease-out',
                      transitionDelay: `${i * 100}ms`,
                    }}
                  >
                    <span className={`absolute inset-0 ${theme === 'dark' ? 'bg-orange-900/60' : 'bg-orange-100'} group-hover:scale-110 transition-transform duration-300`}></span>
                    <span className="relative z-10">{tag}</span>
                  </span>
                ))}
              </div>
              
              <div 
                className="flex items-center gap-6"
                style={{
                  transform: `translateY(${Math.max(0, 160 - Math.max(0, scrollY - 900) * 0.5)}px)`,
                  opacity: Math.min(1, Math.max(0, scrollY - 900) / 350),
                  transition: 'transform 1.4s ease-out, opacity 1.4s ease-out',
                }}
              >
                <div className="flex items-center gap-2">
                  <Circle size={20} className="text-orange-500" />
                  <span>Decentralized Systems</span>
                </div>
                <div className="flex items-center gap-2">
                  <DollarSign size={20} className="text-orange-500" />
                  <span>Digital Assets</span>
                </div>
              </div>
            </div>
            
            <div 
              className="flex-1 relative"
              style={{
                opacity: Math.min(1, Math.max(0, scrollY - 900) / 300), // Keep fade-in effect
                transition: 'opacity 1s ease-out', // Remove transform from here
              }}
            >
              <div 
                className={`relative w-full aspect-square rounded-2xl overflow-hidden shadow-2xl`}
                onMouseMove={(e) => handleMouseMove3D(e, 15)}
                onMouseLeave={resetCard}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={resetCard}
                style={{
                  background: theme === 'dark' ? 'rgba(20, 20, 20, 0.7)' : 'rgba(255, 255, 255, 0.7)',
                  backdropFilter: 'blur(10px)',
                  boxShadow: theme === 'dark' 
                    ? '0 25px 50px -12px rgba(0, 0, 0, 0.5), 0 0 30px rgba(237, 137, 54, 0.2)' 
                    : '0 25px 50px -12px rgba(0, 0, 0, 0.1), 0 0 30px rgba(237, 137, 54, 0.1)',
                  transformStyle: 'preserve-3d',
                  transition: 'transform 0.2s ease-out',
                }}
              >
                <div className="absolute right-0 top-0 bottom-0 flex items-center justify-center pr-8" style={{ width: '100%', maxWidth: '500px' }}>
                  <div className="relative h-full w-full flex flex-col items-center justify-center py-6">
                    {/* Title at the top */}
                    <div className="absolute top-2 left-0 right-0 text-center">
                      <h3 className="text-xl font-bold bg-gradient-to-r from-amber-400 to-orange-600 text-transparent bg-clip-text mb-1">
                        Areas of Interest
                      </h3>
                    </div>

                    {/* Crypto Areas of Interest - completely removed the center orange blur */}
                    <div className="relative w-full h-full pt-10">
                      {[
                        { name: 'DeFi', icon: '💰', color: 'from-blue-500 to-cyan-600', description: 'Lending, DEXs & Derivatives', movePattern: 'pattern1' },
                        { name: 'RWA', icon: '🏢', color: 'from-green-500 to-emerald-600', description: 'Tokenized Securities & Real Estate', movePattern: 'pattern2' },
                        { name: 'NFTs', icon: '🖼️', color: 'from-pink-500 to-purple-600', description: 'Digital Collectibles', movePattern: 'pattern3' },
                        { name: 'SocialFi', icon: '👥', color: 'from-teal-500 to-green-600', description: 'Social Tokens & Communities', movePattern: 'pattern4' },
                        { name: 'Memecoins', icon: '🐕', color: 'from-yellow-500 to-amber-600', description: 'Community-driven Fun', movePattern: 'pattern5' },
                        { name: 'Restaking', icon: '🔄', color: 'from-red-500 to-orange-600', description: 'Enhanced Security & Yields', movePattern: 'pattern6' },
                        { name: 'DePIN', icon: '📡', color: 'from-indigo-500 to-blue-600', description: 'Decentralized Infrastructure', movePattern: 'pattern7' },
                        { name: 'Cross-Chain', icon: '🌉', color: 'from-violet-500 to-purple-600', description: 'Interoperability Solutions', movePattern: 'pattern8' },
                        { name: 'GameFi', icon: '🎮', color: 'from-rose-500 to-pink-600', description: 'Play-to-Earn Gaming', movePattern: 'pattern9' }
                      ].map((area, i) => {
                        return (
                          <div
                            key={area.name}
                            className={`absolute w-18 h-18 rounded-xl bg-gradient-to-br ${area.color} shadow-lg backdrop-blur-sm flex flex-col items-center justify-center text-white transform hover:scale-110 transition-transform cursor-pointer z-20`}
                            style={{
                              width: i % 3 === 0 ? '80px' : i % 3 === 1 ? '70px' : '75px', 
                              height: i % 3 === 0 ? '80px' : i % 3 === 1 ? '70px' : '75px',
                              boxShadow: '0 8px 20px rgba(0, 0, 0, 0.3)',
                              animation: `floatRandom${i+1} 20s infinite ease-in-out alternate, 
                                         glow ${4 + i % 3}s infinite alternate`,
                              // Set different initial positions for each item (more evenly distributed)
                              left: `${10 + (i * 30) % 75}%`,
                              top: `${10 + ((i * 25) + 15) % 70}%`,
                            }}
                          >
                            <div className="text-2xl mb-1">{area.icon}</div>
                            <div className="text-xs font-bold">{area.name}</div>
                          </div>
                        );
                      })}
                    </div>

                    {/* Live price ticker moved to left side */}
                    <div className="absolute bottom-4 left-0 mx-auto w-4/5 h-8 bg-gray-900/30 backdrop-blur-sm rounded-full overflow-hidden z-30">
                      <div className="flex items-center h-full px-3 text-xs text-amber-400 z-20">
                        <span className="animate-pulse mr-2">●</span> Live Prices
                      </div>
                      <div id="crypto-price-ticker" className="absolute inset-0 flex items-center whitespace-nowrap z-10 pl-24"
                           style={{
                             animation: 'none', // Remove existing animation
                             animationDuration: '25s',
                             animationTimingFunction: 'linear',
                             animationIterationCount: 'infinite',
                             transform: 'translateX(100%)', // Start off screen to the right
                             animation: 'ticker-rtl 25s linear infinite' // Apply new animation
                           }}>
                        {/* This will be filled by API data from the useEffect hook */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* AI Section */}
      <section id="ai" className={`min-h-screen flex items-center relative py-24 ${theme === 'dark' ? 'bg-gray-800/50' : 'bg-gray-200/50'} backdrop-blur-md`}>
        <div className="absolute inset-0 overflow-hidden">
          <img 
            src="/api/placeholder/1920/1080" 
            alt="AI technology visualization" 
            className="w-full h-full object-cover opacity-10"
          />
          <div className={`absolute inset-0 bg-gradient-to-b ${theme === 'dark' ? 'from-gray-900 via-transparent to-gray-900' : 'from-gray-100 via-transparent to-gray-100'}`} />
        </div>

        <div className="container mx-auto px-6 relative z-10">
          <div className="flex flex-col md:flex-row items-center gap-12">
            <div className="flex-1 relative">
              <div className={`absolute -top-10 -left-10 text-9xl opacity-5 font-bold ${theme === 'dark' ? 'text-blue-300' : 'text-blue-700'}`}>03</div>
              <div 
                className="overflow-hidden mb-4"
                style={{
                  transform: `translateY(${Math.max(0, 100 - Math.max(0, scrollY - 1500) * 0.5)}px)`,
                  opacity: Math.min(1, Math.max(0, scrollY - 1500) / 200),
                  transition: 'transform 0.8s ease-out, opacity 0.8s ease-out',
                }}
              >
                <h2 className="text-4xl md:text-6xl font-bold mb-2 relative bg-gradient-to-r from-blue-400 to-indigo-600 text-transparent bg-clip-text">
                  AI Explorer
                </h2>
                <div className="h-1 w-24 bg-gradient-to-r from-blue-500 to-indigo-700 rounded-full" />
              </div>
              
              <div 
                className="text-lg mb-6 opacity-80 max-w-xl"
                style={{
                  transform: `translateY(${Math.max(0, 120 - Math.max(0, scrollY - 1500) * 0.5)}px)`,
                  opacity: Math.min(1, Math.max(0, scrollY - 1500) / 250),
                  transition: 'transform 1s ease-out, opacity 1s ease-out',
                }}
              >
                <p>Venturing into the cutting edge of artificial intelligence by experimenting with various Large Language Models (LLMs) and AI technologies. I explore different protocols including MCP, Vibe coding methodologies, and creative image generation. My focus is on developing intuitive AI interfaces and pioneering new frameworks for effective human-AI collaboration.</p>
              </div>
              
              <div 
                className="flex flex-wrap gap-3 mb-8"
                style={{
                  transform: `translateY(${Math.max(0, 140 - Math.max(0, scrollY - 1500) * 0.5)}px)`,
                  opacity: Math.min(1, Math.max(0, scrollY - 1500) / 300),
                  transition: 'transform 1.2s ease-out, opacity 1.2s ease-out',
                }}
              >
                {['LLM', 'MCP', 'Vibe Coding', 'Image Generation', 'AI Agents'].map((tag, i) => (
                  <span 
                    key={tag} 
                    className={`px-4 py-1 rounded-full text-sm relative overflow-hidden group`}
                    style={{
                      transition: 'all 0.3s ease-out',
                      transitionDelay: `${i * 100}ms`,
                    }}
                  >
                    <span className={`absolute inset-0 ${theme === 'dark' ? 'bg-blue-900/60' : 'bg-blue-100'} group-hover:scale-110 transition-transform duration-300`}></span>
                    <span className="relative z-10">{tag}</span>
                  </span>
                ))}
              </div>
              
              <div 
                className="flex items-center gap-6"
                style={{
                  transform: `translateY(${Math.max(0, 160 - Math.max(0, scrollY - 1500) * 0.5)}px)`,
                  opacity: Math.min(1, Math.max(0, scrollY - 1500) / 350),
                  transition: 'transform 1.4s ease-out, opacity 1.4s ease-out',
                }}
              >
                <div className="flex items-center gap-2">
                  <Cpu size={20} className="text-blue-500" />
                  <span>Future Technology</span>
                </div>
                <div className="flex items-center gap-2">
                  <Circle size={20} className="text-blue-500" />
                  <span>Human-AI Collaboration</span>
                </div>
              </div>
            </div>
            
            <div 
              className="flex-1 relative"
              style={{
                transform: `translateX(${Math.max(0, 100 - Math.max(0, scrollY - 1500) * 0.5)}px)`,
                opacity: Math.min(1, Math.max(0, scrollY - 1500) / 300),
                transition: 'transform 1s ease-out, opacity 1s ease-out',
              }}
            >
              <div 
                className={`relative w-full aspect-square rounded-2xl overflow-hidden shadow-2xl`}
                onMouseMove={(e) => handleMouseMove3D(e, 15)}
                onMouseLeave={resetCard}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={resetCard}
                style={{
                  background: theme === 'dark' ? 'rgba(20, 20, 20, 0.7)' : 'rgba(255, 255, 255, 0.7)',
                  backdropFilter: 'blur(10px)',
                  boxShadow: theme === 'dark' 
                    ? '0 25px 50px -12px rgba(0, 0, 0, 0.5), 0 0 30px rgba(59, 130, 246, 0.2)' 
                    : '0 25px 50px -12px rgba(0, 0, 0, 0.1), 0 0 30px rgba(59, 130, 246, 0.1)',
                  transformStyle: 'preserve-3d',
                  transition: 'transform 0.2s ease-out',
                }}
              >
                <div className="absolute inset-0 flex items-center justify-center">
                  {/* AI Brain visualization */}
                  <div className="relative w-4/5 h-4/5" style={{ transform: 'translateZ(20px)' }}>
                    {/* Neural network visualization */}
                    <div className="w-full h-full relative">
                      {/* Central brain structure */}
                      <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <div className="relative w-48 h-48">
                          {/* Brain outline */}
                          <div 
                            className="absolute inset-0 rounded-full opacity-30"
                            style={{
                              background: 'radial-gradient(circle, rgba(59,130,246,0.5) 0%, rgba(59,130,246,0) 70%)',
                              animation: 'pulse 4s infinite ease-in-out',
                            }}
                          />
                          
                          {/* Neurons */}
                          {[...Array(30)].map((_, i) => {
                            const angle = Math.random() * Math.PI * 2;
                            const distance = Math.random() * 40 + 30;
                            const x = Math.cos(angle) * distance;
                            const y = Math.sin(angle) * distance;
                            const size = Math.random() * 4 + 2;
                            
                            return (
                              <div
                                key={i}
                                className="absolute rounded-full bg-blue-500"
                                style={{
                                  width: `${size}px`,
                                  height: `${size}px`,
                                  left: `calc(50% + ${x}px)`,
                                  top: `calc(50% + ${y}px)`,
                                  transform: 'translate(-50%, -50%)',
                                  opacity: 0.7,
                                  boxShadow: '0 0 8px rgba(59, 130, 246, 0.8)',
                                  animation: `pulse ${2 + Math.random() * 3}s infinite alternate-reverse ease-in-out`,
                                }}
                              />
                            );
                          })}
                          
                          {/* Neural connections */}
                          <svg className="absolute inset-0 w-full h-full" style={{ overflow: 'visible' }}>
                            {[...Array(40)].map((_, i) => {
                              const startAngle = Math.random() * Math.PI * 2;
                              const startDistance = Math.random() * 30 + 20;
                              const startX = Math.cos(startAngle) * startDistance;
                              const startY = Math.sin(startAngle) * startDistance;
                              
                              const endAngle = Math.random() * Math.PI * 2;
                              const endDistance = Math.random() * 30 + 20;
                              const endX = Math.cos(endAngle) * endDistance;
                              const endY = Math.sin(endAngle) * endDistance;
                              
                              return (
                                <line
                                  key={i}
                                  x1={startX + 96}
                                  y1={startY + 96}
                                  x2={endX + 96}
                                  y2={endY + 96}
                                  stroke="rgba(59, 130, 246, 0.3)"
                                  strokeWidth="0.5"
                                  style={{
                                    animation: `pulseOpacity ${3 + Math.random() * 2}s infinite alternate-reverse ease-in-out`,
                                  }}
                                />
                              );
                            })}
                          </svg>
                        </div>
                      </div>
                      
                      {/* Data streams */}
                      <div className="absolute inset-0">
                        {[...Array(5)].map((_, i) => (
                          <div
                            key={i}
                            className="absolute h-0.5 bg-gradient-to-r from-blue-500 to-indigo-500 opacity-70"
                            style={{
                              top: `${20 + i * 15}%`,
                              left: '0',
                              width: '100%',
                              transform: `translateX(${(scrollY % 200) - 100}px)`,
                              filter: 'blur(1px)',
                            }}
                          />
                        ))}
                        
                        {[...Array(5)].map((_, i) => (
                          <div
                            key={i}
                            className="absolute h-0.5 bg-gradient-to-l from-blue-500 to-indigo-500 opacity-70"
                            style={{
                              top: `${25 + i * 15}%`,
                              left: '0',
                              width: '100%',
                              transform: `translateX(${100 - (scrollY % 200)}px)`,
                              filter: 'blur(1px)',
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Bazi Section */}
      <section id="bazi" className="min-h-screen flex items-center relative py-24">
        <div className="absolute inset-0 overflow-hidden">
          <img 
            src="/api/placeholder/1920/1080" 
            alt="Chinese metaphysics background" 
            className="w-full h-full object-cover opacity-10"
          />
          <div className={`absolute inset-0 bg-gradient-to-b ${theme === 'dark' ? 'from-gray-900 via-transparent to-gray-900' : 'from-gray-100 via-transparent to-gray-100'}`} />
        </div>

        <div className="container mx-auto px-6 relative z-10">
          <div className="flex flex-col md:flex-row-reverse items-center gap-12">
            <div className="flex-1 relative">
              <div className={`absolute -top-10 -left-10 text-9xl opacity-5 font-bold ${theme === 'dark' ? 'text-purple-300' : 'text-purple-700'}`}>04</div>
              <div 
                className="overflow-hidden mb-4"
                style={{
                  transform: `translateY(${Math.max(0, 100 - Math.max(0, scrollY - 2100) * 0.5)}px)`,
                  opacity: Math.min(1, Math.max(0, scrollY - 2100) / 200),
                  transition: 'transform 0.8s ease-out, opacity 0.8s ease-out',
                }}
              >
                <h2 className="text-4xl md:text-6xl font-bold mb-2 relative">
                  <span className="bg-gradient-to-r from-purple-400 to-fuchsia-600 text-transparent bg-clip-text">Bazi Practitioner</span>
                  <span className="ml-3 text-2xl opacity-70">八字</span>
                </h2>
                <div className="h-1 w-24 bg-gradient-to-r from-purple-500 to-fuchsia-700 rounded-full" />
              </div>
              
              <div 
                className="text-lg mb-6 opacity-80 max-w-xl"
                style={{
                  transform: `translateY(${Math.max(0, 120 - Math.max(0, scrollY - 2100) * 0.5)}px)`,
                  opacity: Math.min(1, Math.max(0, scrollY - 2100) / 250),
                  transition: 'transform 1s ease-out, opacity 1s ease-out',
                }}
              >
                <p>Practicing the ancient Chinese metaphysical science of fate calculation based on the Four Pillars of Destiny. Bazi reveals innate personality traits and helps to "attract good fortune while avoiding misfortune" (趨吉避凶). This wisdom extends to Feng Shui principles, offering holistic guidance on harmonizing environments with personal energies and cosmic patterns.</p>
              </div>
              
              <div 
                className="flex flex-wrap gap-3 mb-8"
                style={{
                  transform: `translateY(${Math.max(0, 140 - Math.max(0, scrollY - 2100) * 0.5)}px)`,
                  opacity: Math.min(1, Math.max(0, scrollY - 2100) / 300),
                  transition: 'transform 1.2s ease-out, opacity 1.2s ease-out',
                }}
              >
                {['Four Pillars', 'Chinese Metaphysics', 'Personality Analysis', 'Feng Shui', 'Destiny Reading'].map((tag, i) => (
                  <span 
                    key={tag} 
                    className={`px-4 py-1 rounded-full text-sm relative overflow-hidden group`}
                    style={{
                      transition: 'all 0.3s ease-out',
                      transitionDelay: `${i * 100}ms`,
                    }}
                  >
                    <span className={`absolute inset-0 ${theme === 'dark' ? 'bg-purple-900/60' : 'bg-purple-100'} group-hover:scale-110 transition-transform duration-300`}></span>
                    <span className="relative z-10">{tag}</span>
                  </span>
                ))}
              </div>
              
              <div 
                className="flex items-center gap-6"
                style={{
                  transform: `translateY(${Math.max(0, 160 - Math.max(0, scrollY - 2100) * 0.5)}px)`,
                  opacity: Math.min(1, Math.max(0, scrollY - 2100) / 350),
                  transition: 'transform 1.4s ease-out, opacity 1.4s ease-out',
                }}
              >
                <div className="flex items-center gap-2">
                  <Moon size={20} className="text-purple-500" />
                  <span>Cosmic Patterns</span>
                </div>
                <div className="flex items-center gap-2">
                  <Star size={20} className="text-purple-500" />
                  <span>Personal Destiny</span>
                </div>
              </div>
            </div>
            
            <div 
              className="flex-1 relative"
              style={{
                opacity: Math.min(1, Math.max(0, scrollY - 2100) / 300), // Keep fade-in effect
                transition: 'opacity 1s ease-out', // Remove transform
              }}
            >
              <div 
                className={`relative w-full aspect-square rounded-2xl overflow-hidden shadow-2xl`}
                onMouseMove={(e) => handleMouseMove3D(e, 15)}
                onMouseLeave={resetCard}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={resetCard}
                style={{
                  background: theme === 'dark' ? 'rgba(20, 20, 20, 0.7)' : 'rgba(255, 255, 255, 0.7)',
                  backdropFilter: 'blur(10px)',
                  boxShadow: theme === 'dark' 
                    ? '0 25px 50px -12px rgba(0, 0, 0, 0.5), 0 0 30px rgba(168, 85, 247, 0.2)' 
                    : '0 25px 50px -12px rgba(0, 0, 0, 0.1), 0 0 30px rgba(168, 85, 247, 0.1)',
                  transformStyle: 'preserve-3d',
                  transition: 'transform 0.2s ease-out',
                }}
              >
                <div className="absolute right-0 top-0 bottom-0 flex items-center justify-center pr-8" style={{ width: '100%', maxWidth: '500px' }}>
                  <div className="relative w-full h-4/5 flex flex-col items-center justify-start py-6">
                    {/* Title */}
                    <div className="text-xl font-bold bg-gradient-to-r from-purple-400 to-purple-600 text-transparent bg-clip-text mb-4">
                      Five Elements & Bazi Chart
                    </div>
                    
                    {/* Five Elements Cycle - with reduced bottom margin */}
                    <div className="w-56 h-56 mx-auto relative mb-4">
                      <svg viewBox="0 0 200 200" className="w-full h-full">
                        <defs>
                          <marker id="elementArrow" markerWidth="10" markerHeight="7" 
                                  refX="0" refY="3.5" orient="auto">
                            <polygon points="0 0, 10 3.5, 0 7" fill="#8b5cf6" />
                          </marker>
                        </defs>
                        
                        <g className="animate-spin-slow">
                          {/* Arrows showing the proper generating cycle: Earth->Metal->Water->Wood->Fire->Earth */}
                          <path d="M133,124 A50,50 0 0,1 67,124" stroke="#8b5cf6" strokeWidth="2" 
                                strokeDasharray="3,1.5" fill="none" markerEnd="url(#elementArrow)" />
                          <path d="M67,124 A50,50 0 0,1 54,66" stroke="#8b5cf6" strokeWidth="2" 
                                strokeDasharray="3,1.5" fill="none" markerEnd="url(#elementArrow)" />
                          <path d="M54,66 A50,50 0 0,1 100,30" stroke="#8b5cf6" strokeWidth="2" 
                                strokeDasharray="3,1.5" fill="none" markerEnd="url(#elementArrow)" />
                          <path d="M100,30 A50,50 0 0,1 146,66" stroke="#8b5cf6" strokeWidth="2" 
                                strokeDasharray="3,1.5" fill="none" markerEnd="url(#elementArrow)" />
                          <path d="M146,66 A50,50 0 0,1 133,124" stroke="#8b5cf6" strokeWidth="2" 
                                strokeDasharray="3,1.5" fill="none" markerEnd="url(#elementArrow)" />
                          
                          {/* Five elements evenly positioned in a pentagon */}
                          <circle cx="100" cy="30" r="22" fill="#4ade80" /> {/* Wood at top */}
                          <circle cx="146" cy="66" r="22" fill="#f87171" /> {/* Fire at top right */}
                          <circle cx="133" cy="124" r="22" fill="#8B4513" /> {/* Earth at bottom right */}
                          <circle cx="67" cy="124" r="22" fill="#FFD700" /> {/* Metal at bottom left - gold color */}
                          <circle cx="54" cy="66" r="22" fill="#60a5fa" /> {/* Water at top left */}
                          
                          {/* Element Chinese characters */}
                          <text x="100" y="35" textAnchor="middle" fill="white" fontSize="20" fontWeight="bold">木</text>
                          <text x="146" y="71" textAnchor="middle" fill="white" fontSize="20" fontWeight="bold">火</text>
                          <text x="133" y="129" textAnchor="middle" fill="white" fontSize="20" fontWeight="bold">土</text>
                          <text x="67" y="129" textAnchor="middle" fill="white" fontSize="20" fontWeight="bold">金</text>
                          <text x="54" y="71" textAnchor="middle" fill="white" fontSize="20" fontWeight="bold">水</text>
                        </g>
                      </svg>
                    </div>

                    {/* Bazi Chart - adjusted to be closer to the circle */}
                    <div className="relative w-full border-2 border-purple-500 rounded-lg p-2 sm:p-3 bg-opacity-20 backdrop-blur-sm grid grid-cols-2 sm:grid-cols-4 gap-2" 
                      style={{ 
                        background: theme === 'dark' ? 'rgba(30, 30, 40, 0.4)' : 'rgba(255, 255, 255, 0.4)',
                        transform: 'translateZ(40px)',
                      }}
                    >
                      {[
                        { pillar: '年柱', heavenlyStem: '丁', earthlyBranch: '丑' },
                        { pillar: '月柱', heavenlyStem: '丙', earthlyBranch: '午' },
                        { pillar: '日柱', heavenlyStem: '甲', earthlyBranch: '辰' },
                        { pillar: '时柱', heavenlyStem: '甲', earthlyBranch: '子' }
                      ].map((data, i) => {
                        const stemInfo = getElementInfo(data.heavenlyStem);
                        const branchInfo = getElementInfo(data.earthlyBranch);
                        return (
                          <div key={data.pillar} className="flex flex-col h-full">
                            <div className="text-center py-2 border-b border-purple-300/30 text-purple-500 font-semibold">{data.pillar}</div>
                            <div className="flex-1 flex flex-col items-center justify-center gap-4 p-2">
                              <div 
                                className="text-3xl font-semibold" 
                                style={{color: stemInfo.color}}
                                title={`Element: ${stemInfo.name}`}
                              >
                                {data.heavenlyStem}
                              </div>
                              <div 
                                className="text-3xl font-semibold"
                                style={{color: branchInfo.color}}
                                title={`Element: ${branchInfo.name}`}
                              >
                                {data.earthlyBranch}
                              </div>
                            </div>
                            <div className="text-center py-2 border-t border-purple-300/30">
                              <span className="px-2 py-0.5 rounded-full text-xs mr-1" style={{
                                backgroundColor: `${stemInfo.color}30`, color: stemInfo.color }}>
                                {stemInfo.name}
                              </span>
                              <span className="px-2 py-0.5 rounded-full text-xs" style={{
                                backgroundColor: `${branchInfo.color}30`, color: branchInfo.color }}>
                                {branchInfo.name}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className={`py-16 ${theme === 'dark' ? 'bg-gray-800/80' : 'bg-gray-200/80'} backdrop-blur-md relative`}>
        <div className="absolute inset-0 overflow-hidden opacity-10">
          <div className={`absolute inset-0 ${
            theme === 'dark' ? 'bg-gradient-to-t from-gray-900 to-transparent' : 'bg-gradient-to-t from-gray-100 to-transparent'
          }`} />
          
          {/* Grid pattern */}
          <div className="absolute inset-0" 
            style={{
              backgroundImage: `
                linear-gradient(to right, rgba(99,102,241,0.1) 1px, transparent 1px),
                linear-gradient(to bottom, rgba(99,102,241,0.1) 1px, transparent 1px)
              `,
              backgroundSize: '40px 40px',
            }}
          />
        </div>
        
        <div className="container mx-auto px-6 relative z-10">
          <div className="max-w-2xl mx-auto text-center">
            <h2 
              className="text-4xl font-bold mb-6 bg-gradient-to-r from-indigo-400 via-purple-500 to-pink-500 text-transparent bg-clip-text"
              style={{
                transform: `translateY(${Math.max(0, 40 - Math.max(0, scrollY - 2700) * 0.1)}px)`,
                opacity: Math.min(1, Math.max(0, scrollY - 2700) / 200),
                transition: 'transform 0.8s ease-out, opacity 0.8s ease-out',
              }}
            >
              Get In Touch
            </h2>
            
            <p 
              className="mb-8 opacity-80"
              style={{
                transform: `translateY(${Math.max(0, 60 - Math.max(0, scrollY - 2700) * 0.1)}px)`,
                opacity: Math.min(1, Math.max(0, scrollY - 2700) / 300),
                transition: 'transform 1s ease-out, opacity 1s ease-out',
              }}
            >
              Interested in connecting? Reach out to discuss impact investing, crypto projects, AI innovations, or a Bazi reading.
            </p>
            
            <div 
              className="flex justify-center gap-6"
              style={{
                transform: `translateY(${Math.max(0, 80 - Math.max(0, scrollY - 2700) * 0.1)}px)`,
                opacity: Math.min(1, Math.max(0, scrollY - 2700) / 400),
                transition: 'transform 1.2s ease-out, opacity 1.2s ease-out',
              }}
            >
              <a 
                href="http://linkedin.com/in/josephl1/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="p-3 rounded-full border border-transparent hover:border-indigo-500/50 transition-colors"
                onMouseEnter={() => setMouseEntered(true)}
                onMouseLeave={() => setMouseEntered(false)}
              >
                <Linkedin size={20} />
              </a>
              <a 
                href="http://twitter.com/joejoejoe168" 
                target="_blank" 
                rel="noopener noreferrer"
                className="p-3 rounded-full border border-transparent hover:border-indigo-500/50 transition-colors"
                onMouseEnter={() => setMouseEntered(true)}
                onMouseLeave={() => setMouseEntered(false)}
              >
                <Twitter size={20} />
              </a>
            </div>
            
            <div 
              className="text-sm opacity-70 pt-8 border-t border-gray-700/30"
              style={{
                transform: `translateY(${Math.max(0, 100 - Math.max(0, scrollY - 2700) * 0.1)}px)`,
                opacity: Math.min(1, Math.max(0, scrollY - 2700) / 500),
                transition: 'transform 1.4s ease-out, opacity 1.4s ease-out',
              }}
            >
              © {new Date().getFullYear()} Joseph Leung. All rights reserved.
            </div>
          </div>
        </div>
      </footer>

      {/* Add global animations */}
      <style jsx global>{`
        @keyframes pulse {
          0% { transform: scale(1); opacity: 0.6; }
          50% { transform: scale(1.1); opacity: 1; }
          100% { transform: scale(1); opacity: 0.6; }
        }
        
        @keyframes float {
          0% { transform: translateY(0) scale(1); }
          100% { transform: translateY(-10px) scale(1.05); }
        }
        
        @keyframes shimmer {
          0% { transform: translateX(-100%); }
          100% { transform: translateX(100%); }
        }
        
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        
        @keyframes growWidth {
          from { width: 0%; }
          to { width: 100%; }
        }
        
        @keyframes pulseOpacity {
          0% { opacity: 0.1; }
          100% { opacity: 0.5; }
        }
        
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(10px); }
          to { opacity: 1; transform: translateY(0); }
        }
        
        @keyframes grow-stem {
          from { height: 0; }
          to { height: 100%; }
        }
        
        @keyframes grow-leaf {
          from { transform: scale(0) rotate(var(--rotation, 0deg)); }
          to { transform: scale(1) rotate(var(--rotation, 0deg)); }
        }
        
        @keyframes bloom {
          0% { transform: scale(0); }
          70% { transform: scale(1.1); }
          100% { transform: scale(1); }
        }

        @keyframes orbit {
          from { transform: rotate(0deg) translateX(0) rotate(0deg); }
          to { transform: rotate(360deg) translateX(0) rotate(-360deg); }
        }

        @keyframes dataPulse {
          0% { width: 0; opacity: 0.9; }
          100% { width: 150px; opacity: 0.2; }
        }

        @keyframes pulse-subtle {
          0% { opacity: 0.1; }
          50% { opacity: 0.2; }
          100% { opacity: 0.1; }
        }

        @keyframes dash {
          to {
            stroke-dashoffset: 24;
          }
        }

        @keyframes cryptoParticle {
          0% {
            transform: translate(0, 0) scale(1);
            opacity: 0;
          }
          10% {
            opacity: 0.6;
          }
          90% {
            opacity: 0.6;
          }
          100% {
            transform: translate(-100px, -20px) scale(0);
            opacity: 0;
          }
        }

        @keyframes rotateY {
          0% { transform: rotateY(0deg); }
          50% { transform: rotateY(180deg); }
          100% { transform: rotateY(360deg); }
        }

        .animate-rotate-y {
          animation: rotateY 6s infinite linear;
          transform-style: preserve-3d;
        }

        .perspective-500 {
          perspective: 500px;
        }

        .transform-style-3d {
          transform-style: preserve-3d;
        }

        .rotate-y-180 {
          transform: rotateY(180deg);
        }

        @keyframes defiParticle {
          0% {
            transform: translate(0, 0) scale(1);
            opacity: 0;
          }
          10% {
            opacity: 0.6;
          }
          90% {
            opacity: 0.6;
          }
          100% {
            transform: translate(300px, 0px) scale(0);
            opacity: 0;
          }
        }

        @keyframes spinTaiji {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }s

        @keyframes spin-slow {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }

        .animate-spin-slow {
          animation: spin-slow 60s linear infinite;
        }

        .marquee-container {
          animation: marquee 8s linear infinite;
        }

        @keyframes marquee {
          0% { transform: translateX(0); }
          45% { transform: translateX(0); }
          50% { transform: translateX(-120px); }
          95% { transform: translateX(-120px); }
          100% { transform: translateX(0); }
        }

        @keyframes defiParticleFixed {
          0% {
            transform: translate(0, 0) scale(1);
            opacity: 0;
          }
          10% {
            opacity: 0.6;
          }
          90% {
            opacity: 0.6;
          }
          100% {
            transform: translate(250px, 0px) scale(0);
            opacity: 0;
          }
        }

        @keyframes marquee-smooth {
          0% { transform: translateX(0); }
          100% { transform: translateX(-50%); } /* Translate by half the total width */
        }

        .animate-marquee-smooth {
          animation: marquee-smooth 12s linear infinite; /* Adjust duration as needed */
        }

        @keyframes ticker {
          0% { transform: translateX(0); }
          100% { transform: translateX(-50%); }
        }

        .animate-ticker {
          animation: ticker 20s linear infinite;
        }

        .animate-spin-slower {
          animation: spin 15s linear infinite;
        }

        @keyframes floatKeywords {
          0% { transform: translate(0, 0) rotate(0deg); opacity: 0; }
          10% { opacity: 0.15; }
          90% { opacity: 0.15; }
          100% { transform: translate(${Math.random() > 0.5 ? '' : '-'}${10 + Math.random() * 20}px, ${Math.random() > 0.5 ? '' : '-'}${10 + Math.random() * 20}px) rotate(${Math.random() > 0.5 ? '' : '-'}${1 + Math.random() * 3}deg); opacity: 0; }
        }

        .keywords-container {
          position: absolute;
          width: 100%;
          height: 100%;
          transform: translateZ(0);
        }

        .keyword-item {
          position: absolute;
          font-size: 2rem;
          font-weight: bold;
          white-space: nowrap;
          will-change: transform, opacity;
          transition: transform 0.3s ease-out;
          animation: pulseKeywords 8s infinite ease-in-out;
          z-index: 1;
          text-shadow: 0 0 15px rgba(99, 102, 241, 0.6);
        }

        @keyframes pulseKeywords {
          0% { opacity: 0; }
          30% { opacity: 0.2 + (Math.random() * 0.3); }
          70% { opacity: 0.2 + (Math.random() * 0.3); }
          100% { opacity: 0; }
        }

        @media (max-width: 768px) {
          .keyword-item {
            font-size: 1.5rem;
          }
        }

        @keyframes shimmer {
          0% { background-position: 200% 0; }
          100% { background-position: -200% 0; }
        }

        @keyframes glow {
          0% { box-shadow: 0 0 5px rgba(251, 191, 36, 0.3); }
          100% { box-shadow: 0 0 20px rgba(251, 191, 36, 0.6); }
        }

        @keyframes floatRandom1 {
          0%, 100% { transform: translate(0, 0) scale(1); }
          20% { transform: translate(50px, 20px) scale(1.05); }
          40% { transform: translate(20px, 70px) scale(0.95); }
          60% { transform: translate(-30px, 40px) scale(1.02); }
          80% { transform: translate(-60px, -30px) scale(0.98); }
        }

        @keyframes floatRandom2 {
          0%, 100% { transform: translate(0, 0) scale(1); }
          25% { transform: translate(-40px, -50px) scale(1.03); }
          45% { transform: translate(30px, -30px) scale(0.97); }
          65% { transform: translate(60px, 20px) scale(1.01); }
          85% { transform: translate(10px, 60px) scale(0.99); }
        }

        @keyframes floatRandom3 {
          0%, 100% { transform: translate(0, 0) scale(1); }
          15% { transform: translate(-20px, 40px) scale(1.04); }
          35% { transform: translate(-60px, -10px) scale(0.96); }
          55% { transform: translate(10px, -50px) scale(1.02); }
          75% { transform: translate(50px, 10px) scale(0.98); }
        }

        @keyframes floatRandom4 {
          0%, 100% { transform: translate(0, 0) scale(1); }
          20% { transform: translate(30px, -60px) scale(1.01); }
          40% { transform: translate(50px, 0px) scale(0.97); }
          60% { transform: translate(10px, 50px) scale(1.03); }
          80% { transform: translate(-40px, 20px) scale(0.99); }
        }

        @keyframes floatRandom5 {
          0%, 100% { transform: translate(0, 0) scale(1); }
          25% { transform: translate(-50px, 10px) scale(1.02); }
          45% { transform: translate(-10px, -40px) scale(0.98); }
          65% { transform: translate(40px, -10px) scale(1.04); }
          85% { transform: translate(20px, 30px) scale(0.96); }
        }

        @keyframes floatRandom6 {
          0%, 100% { transform: translate(0, 0) scale(1); }
          15% { transform: translate(20px, 50px) scale(0.97); }
          35% { transform: translate(-30px, 30px) scale(1.03); }
          55% { transform: translate(-50px, -20px) scale(0.99); }
          75% { transform: translate(10px, -40px) scale(1.02); }
        }

        @keyframes floatRandom7 {
          0%, 100% { transform: translate(0, 0) scale(1); }
          20% { transform: translate(-20px, -30px) scale(1.01); }
          40% { transform: translate(40px, -50px) scale(0.97); }
          60% { transform: translate(50px, 30px) scale(1.03); }
          80% { transform: translate(-40px, 60px) scale(0.99); }
        }

        @keyframes floatRandom8 {
          0%, 100% { transform: translate(0, 0) scale(1); }
          25% { transform: translate(60px, -10px) scale(0.98); }
          45% { transform: translate(20px, 40px) scale(1.02); }
          65% { transform: translate(-30px, 60px) scale(0.96); }
          85% { transform: translate(-60px, 0px) scale(1.04); }
        }

        @keyframes floatRandom9 {
          0%, 100% { transform: translate(0, 0) scale(1); }
          15% { transform: translate(30px, 60px) scale(1.03); }
          35% { transform: translate(-40px, 20px) scale(0.97); }
          55% { transform: translate(-10px, -30px) scale(1.01); }
          75% { transform: translate(50px, -40px) scale(0.99); }
        }

        @keyframes ticker-rtl {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(-100%);
          }
        }
        .animate-ticker-rtl {
          animation: ticker-rtl 25s linear infinite;
        }
      `}</style>
    </div>
  );
};

export default App;